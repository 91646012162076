import { defineStore } from 'pinia';

export type TranslationPath = {
  [key: string]: string
};

interface State {
  pageTranslations: TranslationPath | null
}

export const useI18nStore = defineStore('i18nStore', {
  state: (): State => ({
    pageTranslations: null,
  }),
  actions: {
    setPaths (paths: TranslationPath) {
      this.pageTranslations = paths;
    },
  },
});
